



























































































































































































































































































import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import Datepicker from "@/components/common/datepicker/Date.vue";
import { FreightCompany, Order } from "@/types/orders";
import moment from "moment";
import { ordersModule } from "@/store/orders";
import { LocaleMessage } from "vue-i18n";
import ToolsBar from "@/components/ToolsBar.vue";
import { OrderColor } from "@/types/orders";
import useTemplateRef from "vue";

@Component({
  components: { Datepicker, ToolsBar },
})

export default class OrderRowContent extends Vue {
  @Prop({ required: true }) order!: Order;
  @Prop({ required: true }) fieldsAreFilled!: boolean;
  @Prop({ required: true }) isAdmin!: boolean;
  errorMessage: LocaleMessage | null = "";
  activeTrackingNumber = "";


  onChanged() {
    (this.$refs.theForm as any).disabled=true;

    this.$emit("changed", () => {
      (this.$refs.theForm as any).disabled=false;
    });
    return;
  }

  mounted() {    
    if (this.order.deliveryType === "Other - See comments") {
      this.order.freightCompany = "Other";
    }
  }

  get OrderColor(): string {
    switch (this.order.status) {
      case 0:
        return OrderColor.NEW;
      case 1:
        return OrderColor.ACCEPTED;
      case 2:
        return OrderColor.PLANNED;
      case 3:
        return OrderColor.DELIVERED;
      case 4:
        return OrderColor.COMPLETED;
      default: {
        return "";
      }
    }
  }

  changeCompleted() {
    this.order.orderCompleted = !this.order.orderCompleted;
    this.onChanged();
  }

  orderAccepted() {
    this.order.plannedShipmentDate = this.order.desiredDelivery;
    this.onChanged();
  }

  get freighCompanyList(): FreightCompany[] {
    return ordersModule.freighCompanyList;
  }

  admissionDateFormat(date: Date): string {
    return moment(date).format("YYYY-MM-DD");
  }

  addField() {
    if (this.activeTrackingNumber) {
      this.order.trackingIds.push(this.activeTrackingNumber);
      this.activeTrackingNumber = "";
      this.onChanged();
    }
  }

  removeField(index: number) {
    this.order.trackingIds.splice(index, 1);
    this.onChanged();
  }
}
