import { AxiosError, AxiosResponse } from "axios";

import { Api } from "@/api/axios";
import { LoginResponse } from "@/types/auth";

export default class AuthApi extends Api {
  usersEndpointUrl: string;
  constructor(baseUrl: string | undefined, usersEndpointUrl: string) {
    super({
      baseURL: baseUrl,
    });    
    this.usersEndpointUrl = baseUrl + usersEndpointUrl;
    this.authenticate = this.authenticate.bind(this);
  }

  /**
   * Authenticate a user
   * @returns {Promise<LoginResponse>} - The authentication response
   */
  public authenticate(formData: FormData): Promise<LoginResponse> {
    return this.post<LoginResponse>(
      "/api/user/login",
      //"https://sbf.dev.ucsit.se/api/user/login",
      //"https://localhost:7214/api/user/login",
      formData
    )
      .then((response: AxiosResponse<LoginResponse>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }
}
